import axios from '@axiosV2'

const preview = params =>
  axios.get('/admin/orders/manual-export/storage/preview', {
    params,
  })

const send = data => axios.post('/admin/orders/manual-export/storage/send', data)

export default {
  preview,
  send,
}
