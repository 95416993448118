<template>
  <div>
    <filter-section :cols="[3,3,3, 3]">
      <distributor-id-filter
        slot="col_0"
        v-model="filters"
      />
      <created-at-from-filter
        slot="col_1"
        v-model="filters"
      />
      <created-at-to-filter
        slot="col_2"
        v-model="filters"
      />
      <div slot="col_3">
      </div>
    </filter-section>

    <v-card>
      <v-card-title>
        Preview & resent Distributor report
      </v-card-title>
      <v-card-text>
        <ExportOrder :filters="filters" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
    CreatedAtFromFilter, CreatedAtToFilter,
    DistributorIdFilter,
    FilterSection,
} from '@/components';
import { ref } from '@vue/composition-api';
import ExportOrder from './ExportOrder.vue';

export default {
  components: {
    FilterSection, DistributorIdFilter, CreatedAtFromFilter, CreatedAtToFilter, ExportOrder,
  },

  setup() {
    const filters = ref({
      distributor_id: null,
      created_at_from: null,
      created_at_to: null,
    })

    return {
      filters,
    }
  },
}
</script>
